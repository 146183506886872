exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-allphoto-js": () => import("./../../../src/pages/allphoto.js" /* webpackChunkName: "component---src-pages-allphoto-js" */),
  "component---src-pages-application-form-js": () => import("./../../../src/pages/applicationForm.js" /* webpackChunkName: "component---src-pages-application-form-js" */),
  "component---src-pages-blacklist-firm-js": () => import("./../../../src/pages/blacklist-firm.js" /* webpackChunkName: "component---src-pages-blacklist-firm-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-document-video-js": () => import("./../../../src/pages/document-video.js" /* webpackChunkName: "component---src-pages-document-video-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-greivances-js": () => import("./../../../src/pages/greivances.js" /* webpackChunkName: "component---src-pages-greivances-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-key-institution-js": () => import("./../../../src/pages/key-institution.js" /* webpackChunkName: "component---src-pages-key-institution-js" */),
  "component---src-pages-km-content-in-focus-js": () => import("./../../../src/pages/km-content/in_focus.js" /* webpackChunkName: "component---src-pages-km-content-in-focus-js" */),
  "component---src-pages-magazine-detail-js": () => import("./../../../src/pages/magazine_detail.js" /* webpackChunkName: "component---src-pages-magazine-detail-js" */),
  "component---src-pages-magazines-js": () => import("./../../../src/pages/magazines.js" /* webpackChunkName: "component---src-pages-magazines-js" */),
  "component---src-pages-navigation-page-about-js": () => import("./../../../src/pages/navigation-page/about.js" /* webpackChunkName: "component---src-pages-navigation-page-about-js" */),
  "component---src-pages-navigation-page-contact-js": () => import("./../../../src/pages/navigation-page/contact.js" /* webpackChunkName: "component---src-pages-navigation-page-contact-js" */),
  "component---src-pages-navigation-page-km-js": () => import("./../../../src/pages/navigation-page/KM.js" /* webpackChunkName: "component---src-pages-navigation-page-km-js" */),
  "component---src-pages-navigation-page-notice-js": () => import("./../../../src/pages/navigation-page/notice.js" /* webpackChunkName: "component---src-pages-navigation-page-notice-js" */),
  "component---src-pages-navigation-page-project-js": () => import("./../../../src/pages/navigation-page/project.js" /* webpackChunkName: "component---src-pages-navigation-page-project-js" */),
  "component---src-pages-navigation-page-reports-js": () => import("./../../../src/pages/navigation-page/Reports.js" /* webpackChunkName: "component---src-pages-navigation-page-reports-js" */),
  "component---src-pages-navigation-page-test-js": () => import("./../../../src/pages/navigation-page/test.js" /* webpackChunkName: "component---src-pages-navigation-page-test-js" */),
  "component---src-pages-notification-js": () => import("./../../../src/pages/Notification.js" /* webpackChunkName: "component---src-pages-notification-js" */),
  "component---src-pages-organization-js": () => import("./../../../src/pages/organization.js" /* webpackChunkName: "component---src-pages-organization-js" */),
  "component---src-pages-procurement-complaint-js": () => import("./../../../src/pages/procurement-complaint.js" /* webpackChunkName: "component---src-pages-procurement-complaint-js" */),
  "component---src-pages-project-component-js": () => import("./../../../src/pages/project-component.js" /* webpackChunkName: "component---src-pages-project-component-js" */),
  "component---src-pages-publication-detail-js": () => import("./../../../src/pages/publication_detail.js" /* webpackChunkName: "component---src-pages-publication-detail-js" */),
  "component---src-pages-publication-js": () => import("./../../../src/pages/publication.js" /* webpackChunkName: "component---src-pages-publication-js" */),
  "component---src-pages-quick-link-acessibility-js": () => import("./../../../src/pages/Quick-link/acessibility.js" /* webpackChunkName: "component---src-pages-quick-link-acessibility-js" */),
  "component---src-pages-quick-link-copyright-js": () => import("./../../../src/pages/Quick-link/copyright.js" /* webpackChunkName: "component---src-pages-quick-link-copyright-js" */),
  "component---src-pages-quick-link-disclaimer-js": () => import("./../../../src/pages/Quick-link/disclaimer.js" /* webpackChunkName: "component---src-pages-quick-link-disclaimer-js" */),
  "component---src-pages-quick-link-policy-js": () => import("./../../../src/pages/Quick-link/policy.js" /* webpackChunkName: "component---src-pages-quick-link-policy-js" */),
  "component---src-pages-quick-link-screenreader-js": () => import("./../../../src/pages/Quick-link/screenreader.js" /* webpackChunkName: "component---src-pages-quick-link-screenreader-js" */),
  "component---src-pages-quick-link-term-js": () => import("./../../../src/pages/Quick-link/term.js" /* webpackChunkName: "component---src-pages-quick-link-term-js" */),
  "component---src-pages-quick-link-website-policy-js": () => import("./../../../src/pages/Quick-link/website_policy.js" /* webpackChunkName: "component---src-pages-quick-link-website-policy-js" */),
  "component---src-pages-react-award-js": () => import("./../../../src/pages/react/award.js" /* webpackChunkName: "component---src-pages-react-award-js" */),
  "component---src-pages-react-notification-detail-js": () => import("./../../../src/pages/react/notification_detail.js" /* webpackChunkName: "component---src-pages-react-notification-detail-js" */),
  "component---src-pages-react-notification-js": () => import("./../../../src/pages/react/notification.js" /* webpackChunkName: "component---src-pages-react-notification-js" */),
  "component---src-pages-react-recruitment-detail-js": () => import("./../../../src/pages/react/recruitment_detail.js" /* webpackChunkName: "component---src-pages-react-recruitment-detail-js" */),
  "component---src-pages-react-recruitment-js": () => import("./../../../src/pages/react/recruitment.js" /* webpackChunkName: "component---src-pages-react-recruitment-js" */),
  "component---src-pages-react-tender-detail-js": () => import("./../../../src/pages/react/tender_detail.js" /* webpackChunkName: "component---src-pages-react-tender-detail-js" */),
  "component---src-pages-react-tender-js": () => import("./../../../src/pages/react/tender.js" /* webpackChunkName: "component---src-pages-react-tender-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/Report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-reports-detail-js": () => import("./../../../src/pages/reports_detail.js" /* webpackChunkName: "component---src-pages-reports-detail-js" */),
  "component---src-pages-rti-js": () => import("./../../../src/pages/rti.js" /* webpackChunkName: "component---src-pages-rti-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-social-media-y-splide-js": () => import("./../../../src/pages/social-media/y-splide.js" /* webpackChunkName: "component---src-pages-social-media-y-splide-js" */),
  "component---src-pages-social-media-youtube-2-js": () => import("./../../../src/pages/social-media/youtube2.js" /* webpackChunkName: "component---src-pages-social-media-youtube-2-js" */),
  "component---src-pages-social-media-youtube-splide-js": () => import("./../../../src/pages/social-media/Youtube-splide.js" /* webpackChunkName: "component---src-pages-social-media-youtube-splide-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-tender-table-js": () => import("./../../../src/pages/tender_table.js" /* webpackChunkName: "component---src-pages-tender-table-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-updates-of-the-week-details-js": () => import("./../../../src/pages/updates_of_the_week_details.js" /* webpackChunkName: "component---src-pages-updates-of-the-week-details-js" */),
  "component---src-pages-updates-of-the-week-js": () => import("./../../../src/pages/updates_of_the_week.js" /* webpackChunkName: "component---src-pages-updates-of-the-week-js" */),
  "component---src-pages-who-is-who-2-js": () => import("./../../../src/pages/who-is-who-2.js" /* webpackChunkName: "component---src-pages-who-is-who-2-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-display-contact-js": () => import("./../../../src/templates/display_contact.js" /* webpackChunkName: "component---src-templates-display-contact-js" */),
  "component---src-templates-magazines-js": () => import("./../../../src/templates/magazines.js" /* webpackChunkName: "component---src-templates-magazines-js" */),
  "component---src-templates-notification-display-js": () => import("./../../../src/templates/notification_display.js" /* webpackChunkName: "component---src-templates-notification-display-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-recruitment-display-js": () => import("./../../../src/templates/recruitment_display.js" /* webpackChunkName: "component---src-templates-recruitment-display-js" */),
  "component---src-templates-reports-js": () => import("./../../../src/templates/reports.js" /* webpackChunkName: "component---src-templates-reports-js" */),
  "component---src-templates-tender-display-js": () => import("./../../../src/templates/tender_display.js" /* webpackChunkName: "component---src-templates-tender-display-js" */),
  "component---src-templates-update-display-js": () => import("./../../../src/templates/update_display.js" /* webpackChunkName: "component---src-templates-update-display-js" */)
}

